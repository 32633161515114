import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment-strftime';

import { Layout } from '../components/index';
import { htmlToReact, safePrefix } from '../utils';
import { PostSEO } from '../components/PostSEO';

export default function Post(props) {
  console.log(props);

  return (
    <>
      <PostSEO
        title={props.pageContext.frontmatter.title}
        excerpt={props.pageContext.frontmatter.excerpt}
        thumbnailImg={props.pageContext.frontmatter.thumb_img_path}
      />
      <Layout {...props}>
        <Article className="post post-full">
          <header className="post-header">
            <h1 className="post-title underline">
              {_.get(props, 'pageContext.frontmatter.title')}
            </h1>
          </header>
          {_.get(props, 'pageContext.frontmatter.subtitle') && (
            <div className="post-subtitle">
              {htmlToReact(_.get(props, 'pageContext.frontmatter.subtitle'))}
            </div>
          )}
          {_.get(props, 'pageContext.frontmatter.content_img_path') && (
            <div className="post-thumbnail">
              <img
                src={safePrefix(
                  _.get(props, 'pageContext.frontmatter.content_img_path')
                )}
                alt={_.get(props, 'pageContext.frontmatter.title')}
              />
            </div>
          )}
          <div className="post-content">
            {htmlToReact(_.get(props, 'pageContext.html'))}
          </div>
          <footer className="post-meta">
            <time
              className="published"
              datetime={moment(
                _.get(props, 'pageContext.frontmatter.date')
              ).strftime('%Y-%m-%d %H:%M')}
            >
              {moment(_.get(props, 'pageContext.frontmatter.date')).strftime(
                '%A, %B %e, %Y'
              )}
            </time>
          </footer>
        </Article>
      </Layout>
    </>
  );
}

const Article = styled.article`
  .twitter-tweet-rendered {
    margin-left: auto;
    margin-right: auto;
  }
`;
