import React from 'react';
import Helmet from 'react-helmet';

const baseUrl = 'https://seya.dev/';

export const PostSEO = props => {
  const title = `${props.title} | Seya Blog`;

  return (
    <Helmet>
      <meta name="description" content={props.excerpt} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={baseUrl + props.postSlug} />
      <meta property="og:image" content={baseUrl + props.thumbnailImg} />
      <meta property="og:site_name" content="Seya Blog" />
      <meta property="og:description" content={props.excerpt} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.excerpt} />
    </Helmet>
  );
};
